.header-top-container {
  @apply h-[52px] border-b border-border  relative;

  &-content {
    max-width: 1440px;
    @apply mx-auto flex h-full items-center justify-between px-5 relative;
  }

  &-icon {
    @apply mr-6 cursor-pointer;
  }
  &-price {
    @apply text-xs leading-4;
    .title,
    .price {
      @apply text-xs leading-4;
    }
  }

  &-search {
    @apply h-14;
  }

  &-explorer-change {
    @apply hidden min-[993px]:flex cursor-pointer w-10 h-10 items-center justify-center rounded border border-[#BFBFBF] ml-2;
    .explorer-change-icon {
      @apply w-10 h-10 flex items-center justify-center rounded border ml-2;
    }
  }
  &-right {
    .explorer-card {
      @apply flex absolute right-5 top-14 shadow-[0_6px_24px_0_rgba(0,0,0,0.24)] z-[999];
      .explorer-card-body {
        @apply p-2;
      }
      p {
        @apply w-[91px] h-8 text-[12px] px-[6px] py-2 flex flex-nowrap leading-5 rounded cursor-pointer;
        &:last-child {
          @apply mt-1;
        }
        &:hover {
          @apply bg-[#D8DDE5];
        }
      }
    }
  }
  &-icon-active {
    @apply text-button_active;
  }
}

.header-top-container-mobile {
  @apply w-full h-auto box-border;
  .header-top-container-content {
    @apply w-[100vw] mx-0 px-2;
    .header-top-container-icon {
      @apply ml-0;
    }
  }
  .header-top-container-right {
    @apply flex flex-1 justify-end;
  }
}